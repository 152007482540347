import React from 'react';
import { createRoot } from 'react-dom/client';
import Hero from './components/Hero';
import Header from './components/Header';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Header />
    <Hero />
  </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);

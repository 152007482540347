export default function Header() {
    return (
        <header id="header-section">
            <div id="header-img-container">
                <a className="header-img-link" href='https://twitter.com/AaronTandem' target="_blank">
                    <img className="header-img" src='https://lh3.googleusercontent.com/1QMeOCZvFr4Vby1u8uVcX7eXDjM2c0QuMhp5_AJi4Z5M-Q1RWUxKoJ00a5KNGrYNcOjEMS9tN0DdwgxTI-OaAO6XzXh1wDrvoUkt8-tIHQv2W06R2CvTOYISTOyvlW7Denqu171poqWpIFzp2XPwqSgcPVlYYF16RMVrCnY0pAXxcZ1JEzM6gL3KMq5HA9e3W7HLxsMuTEdZitE6XUYxjHNqBWpPUzmFlVxOkmrrJEg5bRiZJrTNbPTqcT6VjVEGed8kzztuSJJogUk1ccc3WQdmPYPSGMFyALD9d2PFpbVBNKARVjKpEz_8_h49v8P-onfRYPDSiyzRmdwkuHRQYL9FnSDbLTi-p0Gs32IOlMfp5PZnEPAmGYqfrsqPghjJRSSO13U7_0m8gpbkw_jakwXBFmfYCw_hqBry8KNs18NxTCw0SA2ko1HAnvx2yK00_5jy7TA4nEy6GVrWtOzm_Nac-IlgOq63xHWel_WURSCK0esDop632Hh5ShNGYyFB95IVUqT6juQDyVAvTlLot6D7zoSEvYxPuFbKtjD0hD_vR_jupbq1Awh-HBfcuJVCFusIIWt1AO6CxULzDr7oGBsepfPB3pdqTvH0tSUzUCmaeOwPZaLO8JqK0uHUcNtcSpebzmUxktQSuKqEsxRdcNdFSABGnUPbRWNvBRIbUVhqW735df9DDeuG1ZR7wUtt84ARQiE_QBXZ0qCxY8-SmnjMThFpDbx8yd5sAThdl6_WXUHTkhsAv_IVSwFRZO452F1e7hvRPv3VAlHmvX1q3GLcVuM-mVDDLnBDjaaGR6ubC9YSBYJVYVocs1qHR13VZRQi92vZLzSwIeQHGJdWP3GN4ThZhypR5Sg0IpFABmqHAU6QkB6rjH4I69wsqOFfjHtxWzVt3w0T_IEbzx05DwZjKep3FyK3t7yjGRCOiTgt85SxAZ0nElm5oXpBkjHgbIRqfuvsLdF1PIIPky5jFqLg_FcYuYNJLLa-29Zvm2wMqdbwKbw2GcMyCLVBXaEKJiPWkwHzt5Q10rIKhGWPB27iPvyncErES3-M1Pxf1uyOH62joNrPkOuE_ab9_LGGoB1ylVIMG5OYOzDIp4MRwGSRDcAoGLrmw1doQftSOeR5T_d6K2_PdNVWHkplukfqNAyY8GOoKVz5J-pQedb6yNG_8EuUgq0hauWc4Bs=s128-no?authuser=0' alt="twitter-icon" />
                </a>
                <a className="header-img-link" href='https://www.linkedin.com/in/aaron-tandem-7b70a9220/' target="_blank">
                    <img className="header-img" src='https://lh3.googleusercontent.com/SLiO1ib_48gl162tPcpWiDdFTmGczCQlAE7uyHWkBGPbW5-GvlR90gexS5QSq3P1K4xu04_uioDD65EtZ5G9MUDiZSqnkrzdTF1l6BdipqIA68uY6Pc0EHMOzleKPhoAqAF96Zm9OAl4EcQsU9lFElXbXudsahyBd0WfsnqltnHqCuAdpel7ocHuTE7Y2pWjVF6BRf3k_3t-I2n18xeTTwJOz0fHZvTytKclqKC8iETFsNuV02t0yOcpyb4FqWSpUas7GfQMBF7FjEE4v7wv2CGCdD7keJsFwnpCMlQcEN-4tAQR2Ld2KFi5HC62y_tgsjdDj-JQRFp0PWFHqILMxpGVLc_TSrFTDjl33AAEeOh6jf6grmH-ww84e6LHoVQ5rCohvljWQGaPPDpZKpPPMzmry4blVLlj7d9cKvWpUw1bLCqTSptFw1M_9J_7bsamtbMjLvaMyOgokh1CfQ8oWokEN67n2oi1gEu4Xp-_q9QChiikFT4CtV14SfTqaVASjaPrO4iZsUVh0jNRQTYS0qwxMm2T7mQ5ublhB3-i6Rv9d7C8EkSiIPDg0h1mUQYajzB_PPrBUBqUWeo4ZlgaczR0gkJxuUqJ77nleSIMOLZ63YrD5gdb1n31I_ZeBAx4c0cV1JrkIOkBSfzoJEwyN0A4dwZnEgp_0gDrqQFzAt_L5SPTZrcSW3PJEOa_5wkqGw-TjS1k512akBkxdIKLVmMLiNORDeBkPUd-P7k9k275Z9XIK-pMkZBKC-W-EKA_LdhUEGO2EW8Jrv3supXjYiQak3UmzgNbH9zx294KuRrT0jKmQ0VVm-qPGL7qYaxIXlFMTFvqWA14-GB1GJ_a8hpT5j6A6mAL_dZGhQsKGcB0nHTMU_rA2vpDlCnTKoMBFsIE8N654JHyNVQWQd-zO0DiTwLBjzKHVbyOeKk9X8S4RzyrdfVbg8rqJvYU-TQ-yvARDlbfnUNLmsX6KfP2vLksu8JXv7Deq0oLLQx9DcszDnXa-CZuAUq4CtSzK1vEKtSWvCJrrxU6hQgtdgSxztjceucz2ShmAN7UmmBhkmXr9NT56N1rB8MpncK1k0WFyvSyuCN-PeBTUUX9ajeEY3GtTC0NoSEE8yExwjVec3AnpcoVgAcWeS6rZH3PXC7n65O4gJb35oBkaaagKrgaMUtcLJEUJdEGEXYw_ik=s128-no?authuser=0' alt="linkedin-icon"/>
                </a>
                <a className="header-img-link" href='mailto:aaronlbtandem@gmail.com'>
                    <img className="header-img" src='https://lh3.googleusercontent.com/esdTFm9-EccXbLfhCkiFC9uAbMxc4myoPJoxxN7qFxmZgLeyuvZ-o7SQNpXxbrMfP02QPzZVUinnDuS5_LC8ly_j8x2_oRHag5Vq8GQ539-HFz4LtFlL0OePTkq-nTvV8RjV_eoi4vjtLMSIMk4rRTmqjkX31k7ao-x7m51ZMlK46Gq_gqOxqz2bWMh9GzVaDeYzC921RxOkXnUr_Q1QDFTgQN7TgOb-DYQHQwOgSnLlh1_Fa0rYgNvEMAsEcmXZKxK-t4_jp2BNOh2c2j_rrsii9kNJdk3hJbMUVtxdfZENN9i3JOlozv3UpxcEN-b1crJ253cjbPYn5h17XhTWXXZhBEjC7aKzLTPmTF7_WfA0U5h16vIwbr_odAmj10AkTChZzMpU1ALJ5XHOcEzL_lJj4NxZ_t88KM7m5xm3vU_VSRBNK8mVpQS41QgxW4AyioF-6bJgDNnm_TUfyOwuOmChJhmogqOxsx-74JuD67wTAQ6pnmKqC4HcOPZpOoO-9GYDjGxaKHIpE2Bx3lMG75msmAKOdL4isU8eKGofeNXh4dhM0J_5YzQzwLqsyTQb9cC3l3b025FDzWkgiuRmYJ-6m4fbjfMdJMXFZ8c6FYZzrI6n8OQICfFVFxHSO_u_0KQYFsdy9idgVIGQXDoUXFsjOZ38EUYith8AEi8SKzk5LfaCGJh94p-jRRuHiNupYNmB5cClHYM0jFsG6dPGkBWFxxg9ZyQU1Xu4imJOc6YkIZYWNJUJQdwZ1aKLlns7h0rIIhWpVSvcjfdku9YP8E7d7Svopl5KEHEeHZkILtdWOzPgp-fA4W_CVuYX_iO2kxjMmjJtiFYTtWg3QR2fqrcR0FML4DMkhEOG1EcYDlg8WQQk8n6K4r5ZGTcoQJv5hUbLoPy460Kiycs_he6EeXGDNtjofrSPFP8p4MS9NVQ8Q7hIN9ETccsIHnnwYR7TQj0v1JAgM-SINNnKfwwRqxU4WgDv-kXy7OxF3VfY-d5NjI57zecAZv2k0ceQGULxDkPYGCksrb0LnWd4QuP7u_jh32tolYad7UIYLwi6sap-Kbs44qhAkD9H7oaYNEdiaKP92akXr-eELAFIWsfVQr-DtqqbMmPwHzdnURwUH4VJt9X9BA_tDkqeJRhu8IQV1l0QdP4hwhjDiqVlqSwgt9eigM7cxSw405IgooQ=s128-no?authuser=0' alt="email-icon"/>
                </a>
                <a className="header-img-link" href='https://github.com/abiever' target="_blank">
                    <img className="header-img" src='https://pixelartmaker-data-78746291193.nyc3.digitaloceanspaces.com/image/d7e4e1e509c728d.png' alt="github-icon"/>
                </a>
            </div>
        </header>
    )
}
import { Cursor, useTypewriter } from 'react-simple-typewriter'

export default function Hero() {
    const [text, count] = useTypewriter({
        words: [
          "Hey there! Call me Aaron.", "Welcome to my site.", "Please feel free to check out my projects below.", "If you break anything, you buy it.", "Kidding!", ". . . Or am I?", "You can hire me now.",
          //Oh, since you're still here, I might as well introduce myself properly
          //Please feel free to check out my projects below
          //You can get ahold of me by clicking any of the buttons above, too!
        ],
        loop: true,
        delaySpeed: 3000,
      })
    return (
        <div id="hero-section">
            <img id="hero-pic" alt='pixel-face' src='https://lh3.googleusercontent.com/OwMiUgjaVrZoNtEo2pb-_TAbW2YNowUYlp8Yz-AeLUC1Uf0-fOQL50Pz_q9KO6obuTTWwNAdmyVr8gBzT8h0mt2pp2aY1OzS4HbkgxN97ou9gloEUwwCI2sOqwA1egDDlJjUEsw2k4ozkPQ-7C6nDzkiugtfX6BPFIgTQX9Y1gfjQFuYxix4MomAeUOSPDXOCxznBdQpxU8iaHiuGl5bSNMemCfxPWDRAsk3EIvGwLRHFBhiy9K6_jbXO9ML7Nk356zIHf2lgJNinxLIXtQNi0S6lX2VMnD74-p0UVjfBooDI1gGebYwdEp7OlBfRp1mte92FZwYdI8jmnWWMgthvDC_3rZLCCuXhvvOyRKtM_V9MfW6O7npQJHSvjDvbBxSgl0nGjm0Gp-NDfPhAAAqUvVTEhnCtPlpVi5t96G-tSWHLfy52fhLKbftm3Jh9GOjl_bHEdcF0jTpe2m94nOVQVKZl34PENbRXPn7NKKVE7d5Q9RHvy_eTLuz0A4QW4pFUKNTPC8LNuROOIk6YN1FfjN55x-mq29nR7Knb9fPVydM2KpWWp2TK1V2cdiIwlM6aQVYe3MKmjqPgIfmkNBRYm4gUoyswLu0snCK251AN68eePt0pQ1WAeUfmIHQFICtqAxwoDGqroMjdTSOFUR2qwMo5Tkq1eEI06JwNAGU1GPXj7-eTsCE7lX5db6BTF1SOkVbZ9HCG559aUXB6p0gKRUoSHGXy5IS5lzckKHBEtZ_lg-10DzWiBuDoFK-2lgrRAtUXvH3ux7JiUHrX4tbLCDF8xUXR9aVtDcwAILf5vJfRcIFUvwsER_2E8pGoe5jR17hAH5L-hl6xhJc6AlJ4waYGZG-SDYoEU_wCzRu51Vd4hXpyedTVIyFdiZZB8bm77ZD6T33jrPE_eE4kFeD3EQOaEcTn1gwe0Jfc9SCdnzk6ZVVzv76tDASItBZu97dDb8h0LdfTBE0CYY3sOkOx0lQhVHTr0uONMVFzO6OFZghvjyZ5vBuheatxpFXIToCOTgCiSLca4z8lQABUjc-1VaDmQdF6_5WI5-8MCvKiNQlkliqIv5kt_o2_INJ_ORu4bKe8sMR1xzc31MfilEYXdJTFrqTFUZA6G1txoz2BTP3_oaSe4iLGu808GjU4hUp-22YtxCTlRGgka7KkbhAO8Hmu636ggiNGAHWhu8=s320-no?authuser=0'/>

            <div id='hero-text-container'>
                <h2 id='hero-title'>
                    Frontend Engineer
                </h2>
                <h1 id='hero-cursor-text'>
                    <span id='cursor-span'>{text}</span>
                    <Cursor cursorColor='black'/>
                </h1>
            </div>

            <div id='projects-container'>

                <div id='button-description-container'>
                    <h3>Weather App</h3>
                    <a href='https://simple-pink-weather.netlify.app/' target='_blank' rel='noreferrer'>
                        <button id='project-button'>Site</button>
                    </a>
                    <a href='https://github.com/abiever/simple-pink-weather-app' target='_blank' rel='noreferrer'>
                        <button id='project-button'>Code</button>
                    </a>
                    <p id='project-description'>See if it's raining where you are.</p>
                </div>

                <div id='button-description-container'>
                    <h3>Chirpspace</h3>
                    <a href='https://chirpspace.vercel.app/' target='_blank' rel='noreferrer'>
                        <button id='project-button'>Site</button>
                    </a>
                    <a href='https://github.com/abiever/chirpspace-tweets' target='_blank' rel='noreferrer'>
                        <button id='project-button'>Code</button>
                    </a>
                    <p id='project-description'>A quiet place to write Tweets.</p>
                </div>

                <div id='button-description-container'>
                    <h3>Exercise Description Maker</h3>
                    <a href='https://exercise-ai-describer.vercel.app/' target='_blank' rel='noreferrer'>
                        <button id='project-button'>Site</button>
                    </a>
                    <a href='https://github.com/abiever/exercise-ai-describer' target='_blank' rel='noreferrer'>
                        <button id='project-button'>Code</button>
                    </a>
                    <p id='project-description'>Writes exercise how-tos instantly with AI.</p>
                </div>
                {/* use the above as template to create more projects. This should eventually be refactored to something more React appropriate, likely using "props" */}
            </div>
            
        </div>
    )
}